import React from "react";

function Footer() {
  return (
    <div>
      <div className="footer">
        <div className="container">
          <h4>Services</h4>
          <ul>
            <li>
              {" "}
              <a href="/">Frontend websites</a>
            </li>
            <li>
              <a href="/">Full stack websites</a>
            </li>
            <li>
              <a href="/">Singlepage webapp </a>
            </li>
            <li>
              <a href="/">desktop application</a>
            </li>
            <li>
              <a href="/">Android application</a>
            </li>
          </ul>
        </div>
        <div className="container">
          <h4>ERP</h4>
          <ul>
            <li>
              {" "}
              <a href="/">What is ERP</a>
            </li>
            <li>
              <a href="/">Benefits</a>
            </li>
            <li>
              <a href="/">Pricing</a>
            </li>
            <li>
              <a href="/">Help</a>
            </li>
          </ul>
        </div>
        <div className="container">
          <h4>Websites</h4>
          <ul>
            <li>
              {" "}
              <a href="/">Frontend websites</a>
            </li>
            <li>
              <a href="/">Full stack websites</a>
            </li>
            <li>
              <a href="/">Singlepage webapp </a>
            </li>
            <li>
              <a href="/">Help</a>
            </li>
          </ul>
          <h4>Frontend Development</h4>
          <ul>
            <li>
              {" "}
              <a href="/">Overview</a>
            </li>
            <li>
              <a href="/">Technologies we use</a>
            </li>
            <li>
              <a href="/">Pricing</a>
            </li>
            <li>
              <a href="/">Help</a>
            </li>
          </ul>
        </div>
        <div className="container">
          <h4>Fullstack Development</h4>
          <ul>
            <li>
              {" "}
              <a href="/">Overview</a>
            </li>
            <li>
              <a href="/">What is Fullstack development?</a>
            </li>
            <li>
              <a href="/">Pricing</a>
            </li>
            <li>
              <a href="/">What we offer?</a>
            </li>
            <li>
              <a href="/">Benefits</a>
            </li>
            <li>
              <a href="/">Discounts for startup</a>
            </li>
          </ul>
        </div>
        <div className="container">
          <h4>Support</h4>
          <ul>
            <li>
              {" "}
              <a href="/">Deploying webapp</a>
            </li>
            <li>
              <a href="/">Enterprice Support</a>
            </li>
            <li>
              <a href="/">Help</a>
            </li>
          </ul>
          <h4>Report</h4>
          <ul>
            <li>
              {" "}
              <a href="/">Contact Us</a>
            </li>
            <li>
              <a href="/">Report a problem</a>
            </li>
            <li>
              <a href="/">Help</a>
            </li>
          </ul>
          <h4>Accounts</h4>
          <ul>
            <li>
              <a href="/">Login to your account</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="last">
        Copyright &copy; Kaushal software. All right reserved
      </div>
    </div>
  );
}

export default Footer;
