import "./App.css";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Backdrop from "./img/Backdrop.png";
import { Routes, Route } from "react-router-dom";
import ContactUs from "./components/ContactUs";
import { useState } from "react";
import Pricing from "./components/Pricing";

function App() {
  const [loading, setLoading] = useState(true);
  const spinner = document.getElementById("spinner");
  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
      setLoading(false);
    }, 1500);
  }

  return (
    !loading && (
      <>
        <div className="body">
          <Navbar brand="Kaushal Software" />
          <Routes>
            <Route path="/" element={<Home background={Backdrop} />} />
            <Route path="contact" element={<ContactUs />} />
            <Route path="pricing" element={<Pricing/>}/>
          </Routes>
        </div>
        <Footer />
      </>
    )
  );
}

export default App;
