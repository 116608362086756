import React from 'react';
import Card from './Card';


function Home({background}) {
  
  return (<>
  <div className="home">
    <div className='welcome' >
        <img src={background} alt={background} />
      <h1>SAVE 20% now. Boost your Business </h1>
      <p>Welcome to Kaushal Software. <br /> We offer best quality websites to power your business, ERP system to manage day to day accounting, etc</p>
      <div className='btn-con'>
    <button className="btn-t-1" >Read more</button>
    </div>
    </div>
      
    </div>
    
    <Card  />
    </>
  );
}

export default Home;
