import React from "react";
import Web from '../img/abc.jpg';
import Erp from '../img/money2.jpg';
import Phone from '../img/phone.jpg';

function Card() {
  return (
   <div className="card-container">
    <div className="image" >
    <img src={Web} alt="" />
    <div className="details">
        <h2>Web Development</h2>
        <p>High quality web development</p>
        <div className="more">
            <a href="/" className="read-more">Read More</a>
        </div>
    </div>
    </div>
    <div className="image">
    <img src={Erp} alt="" />
    <div className="details">
        <h2>Erp System</h2>
        <p>Erp System to power your business</p>
        <div className="more">
            <a href="/" className="read-more">Read More</a>
        </div>
    </div>
    </div>
    <div className="image">
    <img src={Phone} alt="" />
    <div className="details">
        <h2>Android Development</h2>
        <p>Portable capable application for your business</p>
        <div className="more">
            <a href="/" className="read-more">Read More</a>
        </div>
    </div>
    </div>
   </div>
  );
}

export default Card;
