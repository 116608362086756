import React from "react";
import Background from "../img/Pricing.jpg";
import Web from "../img/abc.jpg";
import Erp from "../img/money2.jpg";
import Phone from "../img/phone.jpg";

function Pricing() {
  return (
    <div className="pricing">
      <img src={Background} alt="" />
      <div className="index-2">
      <div className="pr-container">
        <div className="card-pr br">
          <div className="content-pr ">
            <h2>Bronze</h2>
            <h4>$49</h4>
            <p>
            <ul>
                <li>2 pages website</li>
                <li>fully customizable</li>
                <li>Single page web apps </li>
                <li>custom css</li>
               
              </ul>
            </p>
            <button className="send">Purchase</button>
          </div>
        </div>
      </div>
      <div className="pr-container">
        <div className="card-pr sl">
          <div className="content-pr ">
            <h2>Ruby</h2>
            <h4>$99</h4>
            <p>
            <ul>
                <li>2 pages website</li>
                <li>fully customizable</li>
                <li>Single page web apps </li>
                <li>custom css</li>
                <li>This is a point</li>
                <li>This is a point</li>
              </ul>
            </p>
            <button className="ruby">Purchase</button>
          </div>
        </div>
      </div>
      <div className="pr-container">
        <div className="card-pr gld">
          <div className="content-pr ">
            <h2>Gold</h2>
            <h4>$149</h4>
            <p>
            <ul>
                <li>2 pages website</li>
                <li>fully customizable</li>
                <li>Single page web apps </li>
                <li>custom css</li>
                <li>This is a point</li>
                <li>This is a point</li>
                <li>This is a point</li>
              </ul>
            </p>
            <button className="gold ">Purchase</button>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default Pricing;
