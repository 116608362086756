import React from "react";
import Backdrop from "../img/contact.png";

function ContactUs() {
  return (<>
    <div className="contact">
      <img src={Backdrop} alt="" className="bg" />
      <form>
        <div className="frm">
          <div>
            <p className="head">Contact Us</p>
            <p className="desc">
             Contact us here to get support for our services or have any issues? tell us here.
            </p>
          </div>
          <div className="main">
            <input placeholder="Name" type="text" />
            <input placeholder="Email" type="text" />
            <textarea placeholder="Description" name="desc" id="desc" cols="30" rows="10"></textarea>  
            <button className="send">Send</button>          
          </div>
        </div>
        
      </form>
      <div className="text">Problem or a bug?
        <br />
        Report us here
      </div>
     
    </div>
     <div className="info">
      <div>Contact: <a href="tel:+919454966967">+91 9454966967</a></div>
      <div>Email: <a href="mailto:kaushalsoftware@gmail.com">kaushalsoftware@gmail</a></div>
      <div>Address: <span>33 Anand Nagar Lal Bangla Kanpur-208007</span> </div>
     </div>
     </>
  );
}

export default ContactUs;
