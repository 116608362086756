import React from 'react';
import $ from 'jquery';
import {Link } from 'react-router-dom'

function Navbar(props) {
  $(window).scroll(function () {
    $("nav").toggleClass("scroll", $(this).scrollTop() > 50);
  });
  return (
    <nav>
      <div className="logo">
        <Link to="/">{props.brand}</Link>
      </div>
      <ul className="links">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/">Services</Link></li>
        <li><Link to="/pricing">Pricing</Link></li>
        <li><Link to="/contact">Contact us</Link></li>
      </ul>
    </nav>
  );
}

export default Navbar;
